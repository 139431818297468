exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ambassador-js": () => import("./../../../src/pages/ambassador.js" /* webpackChunkName: "component---src-pages-ambassador-js" */),
  "component---src-pages-app-referral-js": () => import("./../../../src/pages/app-referral.js" /* webpackChunkName: "component---src-pages-app-referral-js" */),
  "component---src-pages-cards-js": () => import("./../../../src/pages/cards.js" /* webpackChunkName: "component---src-pages-cards-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-strapi-job-offer-slug-js": () => import("./../../../src/pages/careers/{strapiJobOffer.slug}.js" /* webpackChunkName: "component---src-pages-careers-strapi-job-offer-slug-js" */),
  "component---src-pages-cloud-mining-js": () => import("./../../../src/pages/cloud-mining.js" /* webpackChunkName: "component---src-pages-cloud-mining-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-flex-vault-js": () => import("./../../../src/pages/flex-vault.js" /* webpackChunkName: "component---src-pages-flex-vault-js" */),
  "component---src-pages-glossary-js": () => import("./../../../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-grow-js": () => import("./../../../src/pages/grow.js" /* webpackChunkName: "component---src-pages-grow-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internal-live-js": () => import("./../../../src/pages/internal/live.js" /* webpackChunkName: "component---src-pages-internal-live-js" */),
  "component---src-pages-investment-strategies-js": () => import("./../../../src/pages/investment-strategies.js" /* webpackChunkName: "component---src-pages-investment-strategies-js" */),
  "component---src-pages-landing-rayn-ads-js": () => import("./../../../src/pages/landing-rayn-ads.js" /* webpackChunkName: "component---src-pages-landing-rayn-ads-js" */),
  "component---src-pages-legal-information-js": () => import("./../../../src/pages/legal-information.js" /* webpackChunkName: "component---src-pages-legal-information-js" */),
  "component---src-pages-licences-js": () => import("./../../../src/pages/licences.js" /* webpackChunkName: "component---src-pages-licences-js" */),
  "component---src-pages-linktree-js": () => import("./../../../src/pages/linktree.js" /* webpackChunkName: "component---src-pages-linktree-js" */),
  "component---src-pages-live-js": () => import("./../../../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-memberships-js": () => import("./../../../src/pages/memberships.js" /* webpackChunkName: "component---src-pages-memberships-js" */),
  "component---src-pages-mining-vault-js": () => import("./../../../src/pages/mining-vault.js" /* webpackChunkName: "component---src-pages-mining-vault-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-referral-js": () => import("./../../../src/pages/referral.js" /* webpackChunkName: "component---src-pages-referral-js" */),
  "component---src-pages-risk-warning-js": () => import("./../../../src/pages/risk-warning.js" /* webpackChunkName: "component---src-pages-risk-warning-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-staking-js": () => import("./../../../src/pages/staking.js" /* webpackChunkName: "component---src-pages-staking-js" */),
  "component---src-pages-supported-assets-js": () => import("./../../../src/pages/supported-assets.js" /* webpackChunkName: "component---src-pages-supported-assets-js" */),
  "component---src-pages-telegram-rules-js": () => import("./../../../src/pages/telegram-rules.js" /* webpackChunkName: "component---src-pages-telegram-rules-js" */),
  "component---src-pages-term-vault-js": () => import("./../../../src/pages/term-vault.js" /* webpackChunkName: "component---src-pages-term-vault-js" */),
  "component---src-pages-terms-conditions-modulr-js": () => import("./../../../src/pages/terms-conditions-modulr.js" /* webpackChunkName: "component---src-pages-terms-conditions-modulr-js" */),
  "component---src-pages-terms-conditions-rayn-platform-js": () => import("./../../../src/pages/terms-conditions-rayn-platform.js" /* webpackChunkName: "component---src-pages-terms-conditions-rayn-platform-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-token-economics-js": () => import("./../../../src/pages/token-economics.js" /* webpackChunkName: "component---src-pages-token-economics-js" */),
  "component---src-templates-localized-news-page-jsx": () => import("./../../../src/templates/LocalizedNewsPage.jsx" /* webpackChunkName: "component---src-templates-localized-news-page-jsx" */),
  "component---src-templates-market-minds-index-jsx": () => import("./../../../src/templates/MarketMindsIndex.jsx" /* webpackChunkName: "component---src-templates-market-minds-index-jsx" */),
  "component---src-templates-news-index-jsx": () => import("./../../../src/templates/NewsIndex.jsx" /* webpackChunkName: "component---src-templates-news-index-jsx" */),
  "component---src-templates-news-page-jsx": () => import("./../../../src/templates/NewsPage.jsx" /* webpackChunkName: "component---src-templates-news-page-jsx" */)
}

